<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row class="padding" style="padding-bottom:0">
        <el-form-item label="节点名称" prop="areaName">
          <el-input v-model="dataInfo.areaName" autocomplete="off" placeholder="请输入节点名称"></el-input>
        </el-form-item>
        <el-form-item label="节点类型" prop="areaType">
          <el-radio-group v-model="dataInfo.areaType" :disabled="dataInfo.areaId">
            <el-radio-button v-for="(item, index) in typeList" :key="index" :label="item.code">{{ item.text }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上级节点" prop="parentId">
          <el-cascader v-model="dataInfo.parentCodeArr" :props="areaProps" :options="areaTreeData"></el-cascader>
        </el-form-item>
        <el-form-item v-if="dataInfo.areaType == 1" label="画布底图" prop="imgUrl">
          <l-upload @success="uploadSuccess" url="api/enterprise/easset/area/layer_img_uplload">
            <el-button type="primary" size="small" icon="el-icon-picture">图片上传</el-button>
          </l-upload>
          <div class="margin-top-mini" v-if="dataInfo.imgUrl">
            <el-image :src="dataInfo.imgUrl"></el-image>
          </div>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      areaTreeData: null,
      areaProps: {
        checkStrictly: true,
        expandTrigger: 'hover',
        value: 'areaId',
        label: 'areaName'
      },
      typeTag: null,
      typeList: [
        { code: 0, text: '分组节点' },
        { code: 1, text: '画布' },
        { code: 2, text: '多边形区域' },
        { code: 3, text: '矩形区域' },
        { code: 4, text: '圆形区域' },
        { code: 5, text: '点标记' }
      ],
      dataRules: {
        areaName: [{ required: true, trigger: 'blur', message: '区域名称不能为空' }],
        imgUrl: [{ required: true, trigger: 'blur', message: '图片不能为空' }]
      }
    }
  },
  mounted() {
    if (this.typeTag === 1) {
      this.typeList = [
        { code: 0, text: '分组节点' },
        { code: 1, text: '画布' }
      ]
    } else if (this.typeTag === 2) {
      this.typeList = [
        { code: 2, text: '多边形区域' },
        { code: 3, text: '矩形区域' },
        { code: 4, text: '圆形区域' },
        { code: 5, text: '点标记' }
      ]
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    uploadSuccess(res) {
      this.dataInfo.imgUrl = res.url
      this.dataInfo.imgWidth = res.width
      this.dataInfo.imgHeight = res.height
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          var r = await this.$lgh.post('api/enterprise/easset/area/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(r)
        }
      })
    }
  }
}
</script>

<style></style>
